<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
      <locale class="d-none d-lg-block" />
      <div style="width: 100%">
        <b-row align-h="center">
          <span style="font-weight: bold">{{
            isSeller
              ? optic
                ? optic.nameFantasy
                : ''
              : userAdminDoc
              ? userAdminDoc.nameFantasy
              : ''
          }}</span>
        </b-row>
      </div>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <feather-icon
        v-if="userAdminDocData"
        icon="ApertureIcon"
        size="21"
        class="text-primary"
        style="cursor: pointer"
        :badge="userAdminDocData.qtyMeasurements"
        @click="
          $route.path !== '/admin/measurements' &&
            $router.push({ name: 'measurements' })
        "
      />

      <b-nav-item-dropdown
        v-if="user"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user ml-1"
      >
        <template #button-content>
          <div v-if="user" class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder" style="margin-bottom: 4px">
              {{ user.email }}
            </p>
            <span v-if="user" class="user-status">{{
              getRoleString(user.role)
            }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          >
            <span v-if="user">{{ user.name[0] }}</span>
          </b-avatar>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="goToProfile()"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t('navbar.profile') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t('logout') }}</span>
        </b-dropdown-item>

        <div v-if="userAdminDocData">
          <b-dropdown-divider />
          <span class="ml-1" style="font-weight: bold">Medições</span>
          <div class="ml-1 mt-1">
            <span>{{ `${userAdminDocData.qtyMeasurements} Utilizadas` }}</span>
          </div>
          <div class="ml-1 mt-1 mb-2">
            <span v-if="isSeller">{{
              `${
                userAdminDocData.allowedMeasurements -
                  userAdminDocData.qtyMeasurements || 0
              } Disponíveis`
            }}</span>
            <span v-else>{{
              `${userAdminDocData.availableMeasurements || 0} Disponíveis`
            }}</span>
          </div>

          <div v-if="isOpticAdmin">
            <span v-if="!isSeller" class="ml-1" style="font-weight: bold"
              >Vendedores</span
            >
            <div class="ml-1 mt-1">
              <span>{{ `${userAdminDocData.activeSellers} Ativos` }}</span>
            </div>
          </div>
        </div>
      </b-nav-item-dropdown>

      <b-row v-else class="mx-1" align-v="center">
        <b-skeleton width="150px" class="mr-2"></b-skeleton>
        <b-skeleton type="avatar" />
      </b-row>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BSkeleton,
  BRow,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import Roles from '@/utils/roles'
import RoleEnum from '@/utils/roles'
import OpticController from '@/controllers/optic.controller'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BSkeleton,
    BRow,

    // Navbar Components
    DarkToggler,
    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      optic: null,
    }
  },
  methods: {
    getRoleString(n) {
      return Roles.getRoleAsString(n)
    },
    async logout() {
      await this.$store.dispatch('auth/logout')
      this.$router.push('/')
    },
    goToProfile() {
      this.$router.push({ name: 'profile' })
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    userAdminDoc() {
      return this.$store.state.auth.userAdminDoc
    },
    isSeller() {
      return this.user.role === RoleEnum.SELLER ? true : false
    },
    userAdminDocData() {
      return this.$store.state.auth.userAdminDoc
    },
    isOpticAdmin() {
      return this.$store.state.auth.user.role === RoleEnum.OPTIC_ADMIN
        ? true
        : false
    },
  },
  async created() {
    if (this.isSeller) {
      this.optic = await new OpticController().getOne(
        this.user.sellerOfOpticDocRef,
      )
    }
  },
}
</script>
