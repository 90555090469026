/**
 * Constant that defines the application current version with the format
 * `vX.X`.
 *
 * @example
 * 0.1
 *
 * @readonly
 */
export const version = 1.12
