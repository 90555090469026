<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span :class="isFromPublic ? 'localeTextPublic' : 'ml-50 text-body'">{{
        currentLocale.name
      }}</span>
    </template>
    <div
      v-if="isFromPublic"
      style="background-color: black; border-radius: 10px"
    >
      <b-dropdown-item
        v-for="localeObj in localeObj"
        :key="localeObj.locale"
        @click="setLanguage(localeObj.locale)"
      >
        <b-img
          :src="localeObj.img"
          height="14px"
          width="22px"
          :alt="localeObj.locale"
        />
        <span class="localeTextPublic">{{ localeObj.name }}</span>
      </b-dropdown-item>
    </div>
    <div v-else>
      <b-dropdown-item
        v-for="localeObj in localeObj"
        :key="localeObj.locale"
        @click="setLanguage(localeObj.locale)"
      >
        <b-img
          :src="localeObj.img"
          height="14px"
          width="22px"
          :alt="localeObj.locale"
        />
        <span class="ml-50">{{ localeObj.name }}</span>
      </b-dropdown-item>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  props: ['isFromPublic'],
  methods: {
    setLanguage(locale) {
      this.$i18n.locale = locale
    },
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.localeObj.find(({ locale }) => locale === this.$i18n.locale)
    },
  },
  setup() {
    /* eslint-disable global-require */
    const localeObj = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.svg'),
        name: 'English',
      },
      {
        locale: 'es',
        img: require('@/assets/images/flags/es.svg'),
        name: 'Español',
      },
      {
        locale: 'pt',
        img: require('@/assets/images/flags/pt.svg'),
        name: 'Português',
      },
      {
        locale: 'it',
        img: require('@/assets/images/flags/it.svg'),
        name: 'Italiano',
      },
      {
        locale: 'de',
        img: require('@/assets/images/flags/de.svg'),
        name: 'Deutsche',
      },
    ]
    /* eslint-disable global-require */

    return {
      localeObj,
    }
  },
}
</script>

<style scoped>
.localeTextPublic {
  color: white;
  padding-left: 10px;
  font-weight: 500;
}
</style>
