export default async function getNavMenuItems(userRole) {
  const navigationData = [
    {
      title: 'navbar.home',
      route: 'home',
      icon: 'HomeIcon',
      meta: {
        hasAccess: [2, 3, 4, 5, 6],
      },
    },
    {
      header: 'navbar.sections',
    },
    {
      title: 'navbar.softwarePartners',
      route: 'software-partners',
      icon: 'BriefcaseIcon',
      meta: {
        hasAccess: [6],
      },
    },
    {
      title: 'navbar.laboratories',
      route: 'labs',
      icon: 'ThermometerIcon',
      meta: {
        hasAccess: [5, 6],
      },
    },
    {
      title: 'navbar.optics',
      route: 'optics',
      icon: 'HomeIcon',
      meta: {
        hasAccess: [4, 5, 6],
      },
    },
    {
      title: 'navbar.sellers',
      route: 'sellers',
      icon: 'DollarSignIcon',
      meta: {
        hasAccess: [3, 4, 5, 6],
      },
    },
    {
      title: 'navbar.measurements',
      route: 'measurements',
      icon: 'ApertureIcon',
      meta: {
        hasAccess: [2, 3, 4, 5, 6],
      },
    },
    {
      title: 'navbar.opticalConsultant',
      route: 'Lens',
      icon: 'EyeIcon',
      target: '_blank',
      meta: {
        hasAccess: [2, 3, 4, 5, 6],
      },
    },
    {
      header: 'navbar.administration',
    },
    {
      title: 'navbar.statistics',
      route: 'stats',
      icon: 'BarChart2Icon',
      meta: {
        hasAccess: [2, 3, 4, 5, 6],
      },
    },
    {
      title: 'navbar.invoices',
      route: 'invoices',
      icon: 'DollarSignIcon',
      meta: {
        hasAccess: [3, 4, 5],
      },
    },
    {
      title: 'navbar.plans',
      route: 'plans',
      icon: 'ShoppingBagIcon',
      meta: {
        hasAccess: [6],
      },
    },
    {
      title: 'navbar.externalIntegrations',
      route: 'external-integrations',
      icon: 'LinkIcon',
      meta: {
        hasAccess: [3, 4, 5, 6],
      },
    },
    {
      title: 'navbar.users',
      route: 'users',
      icon: 'UsersIcon',
      meta: {
        hasAccess: [6],
      },
    },
    {
      title: 'navbar.profile',
      route: 'profile',
      icon: 'UserIcon',
      meta: {
        hasAccess: [2, 3, 4, 5, 6],
      },
    },
  ]

  let filteredNavigationData = navigationData.filter((el) => {
    if (el.header) return el
    else return el.meta.hasAccess.includes(userRole)
  })

  if (filteredNavigationData[filteredNavigationData.length - 1].header)
    filteredNavigationData.pop()

  return filteredNavigationData
}
