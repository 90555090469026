<template>
  <div class="text-center text-primary my-2">
    <b-spinner class="align-middle" small />
    <strong class="ml-1">Loading...</strong>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
export default {
  components: {
    BSpinner,
  },
}
</script>
