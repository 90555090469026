<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <span class="d-none d-sm-inline-block">
        COPYRIGHT © {{ new Date().getFullYear() }} Lensxpert
      </span>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
      - {{ $t('version') }} {{ version }}
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

import { version } from '../../../utils/app.js'

export default {
  components: {
    BLink,
  },

  data: () => ({
    version,
  }),
}
</script>
