/**
 * Class responsible for keeping all application local
 * storage keys.
 */
export default class StorageKeys {
  /**
   * LX_USER_ID
   */
  static userId = 'LX_USER_ID'
}
